@tailwind base;
@tailwind components;
@tailwind utilities;
@import './xmas.css';

#root,
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

[data-color-scheme='dark'] {
  color-scheme: dark;
}

[data-color-scheme='light'] {
  color-scheme: light;
}

.image-white {
  filter: grayscale(1) contrast(0.1) brightness(50);
}

.image-dark {
  filter: grayscale(1) contrast(10) brightness(0.01);
}

.highcharts-container,
.highcharts-container svg {
  overflow: visible !important;
}

.tooltip-container-themed {
  background-color: rgb(var(--colors-neutral-dimmed-heavy));
  border-radius: 0.375em;
  border: 1px solid rgb(var(--colors-divider-main));
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  color: #000;
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
  transition: opacity 0.3s;
  z-index: 9999;
}

.tooltip-container-themed[data-popper-interactive='false'] {
  pointer-events: none;
}

.rc-table-ping-left .rc-table-cell-fix-left-first:after,
.rc-table-ping-left .rc-table-cell-fix-left-last:after {
  box-shadow: inset 10px 0 8px -8px rgb(var(--colors-divider-main));
}

.rc-table-cell-fix-left-first:after,
.rc-table-cell-fix-left-last:after {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: -1px;
  width: 20px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}

.slick-prev,
.slick-next {
  width: 28px !important;
  height: 28px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 28px !important;
}

.slick-prev-dark:before,
.slick-next-dark:before {
  color: rgb(var(--colors-text-secondary)) !important;
}

.realtime-events .slick-dots li button:before,
.realtime-events .slick-dots li.slick-active button:before {
  color: white !important;
}

.white-dots .slick-dots li button:before,
.white-dots .slick-dots li.slick-active button:before {
  color: white !important;
}

.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: rgb(var(--colors-text-primary)) !important;
}

.realtime-events .slick-dots {
  bottom: 1em !important;
}

.headline-card {
  transform: translateZ(0);
}

.left-slick-track .slick-track {
  margin-left: 0;
}

.headline-card {
  background: linear-gradient(
    135deg,
    rgb(var(--colors-headline-gradient-from)) 0%,
    rgb(var(--colors-headline-gradient-via)) 45%,
    rgb(var(--colors-headline-gradient-to)) 90%,
    rgb(var(--colors-headline-gradient-to)) 100%
  );
  box-shadow: 0px 2px 24px 8px rgb(var(--colors-headline-gradient-via) / 0.12);
}

.headline-event-backdrop-gradient {
  background: linear-gradient(
    -10deg,
    rgb(var(--colors-headline-gradient-to) / 1) 0%,
    rgb(var(--colors-headline-gradient-to) / 0.7) 35%,
    rgb(var(--colors-headline-gradient-to) / 0) 50%,
    rgb(var(--colors-headline-gradient-to) / 0.7) 80%,
    rgb(var(--colors-headline-gradient-to) / 0.9) 100%
  );
}

.event-page-header-gradient {
  background: linear-gradient(
    135deg,
    rgb(var(--colors-headline-gradient-from)) 0%,
    rgb(var(--colors-headline-gradient-via)) 45%,
    rgb(var(--colors-headline-gradient-to)) 90%,
    rgb(var(--colors-headline-gradient-to)) 100%
  );
  box-shadow: 0px 2px 24px 8px rgb(var(--colors-headline-gradient-via) / 0.12);
}

.event-page-header-content-gradient {
  background: linear-gradient(
    -10deg,
    rgb(var(--colors-headline-gradient-to) / 0.8) 0%,
    rgb(var(--colors-headline-gradient-to) / 0.3) 35%,
    rgb(var(--colors-headline-gradient-to) / 0) 50%,
    rgb(var(--colors-headline-gradient-to) / 0.7) 70%,
    rgb(var(--colors-headline-gradient-to) / 0.9) 100%
  );
}

.event-page-header-image-gradient {
  background: linear-gradient(
    180deg,
    rgb(var(--colors-headline-gradient-from) / 0) 0%,
    rgb(var(--colors-headline-gradient-via) / 0.2) 70%,
    rgb(var(--colors-headline-gradient-to) / 0.3) 75%,
    rgb(var(--colors-headline-gradient-to) / 0.5) 100%
  );
}
.event-backdrop-gradient {
  background: linear-gradient(
    10deg,
    rgb(var(--colors-event-backdrop) / 1) 0%,
    rgb(var(--colors-event-backdrop) / 0.5) 35%,
    rgb(var(--colors-event-backdrop) / 0) 50%,
    rgb(var(--colors-event-backdrop) / 0.5) 70%,
    rgb(var(--colors-event-backdrop) / 0.8) 100%
  );
}

.event-gradient {
  background: linear-gradient(
    0deg,
    rgb(var(--colors-event-backdrop) / 0.33) 0%,
    rgb(var(--colors-event-backdrop) / 0) 100%
  );
}

.nivo-bar svg {
  overflow: visible;
}

.green-glow {
  box-shadow: 0px 1px 3px 1px rgba(16, 185, 129, 0.12);
}

.amber-glow {
  box-shadow: 0px 1px 3px 1px rgba(245, 158, 11, 0.12);
}

.red-glow {
  box-shadow: 0px 1px 3px 1px rgba(239, 68, 68, 0.12);
}

.realtime-dot {
  background-color: rgb(var(--colors-helpers-error-main));
  height: 1em;
  width: 1em;
  border-radius: 1em;
  animation-name: realtime-dot;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in;
}

.realtime-dot-halo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid rgb(var(--colors-helpers-error-dimmed));
  width: 1.5em;
  height: 1.5em;
  border-radius: 1.5em;
}

@keyframes realtime-dot {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.75);
  }
}

.shine,
.shine-quick {
  position: relative;
  overflow: hidden;
}

.shine:after {
  content: '';
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20%;
  height: 200%;
  opacity: 0;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0.05);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.05) 77%,
    rgba(255, 255, 255, 0.03) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

.shine-quick:after {
  content: '';
  position: absolute;
  top: -50%;
  right: -60%;
  width: 20%;
  height: 200%;
  opacity: 0;
  transform: rotate(-45deg);
  background: rgba(255, 255, 255, 0.05);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.05) 77%,
    rgba(255, 255, 255, 0.03) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* Hover state - trigger effect */
.shine:hover:after {
  opacity: 1;
  left: 130%;
  transition-property: left, top, opacity;
  transition-duration: 1.5s, 1.5s, 0.15s;
  transition-timing-function: ease;
}

/* Hover state - trigger effect */
.shine-quick:hover:after {
  opacity: 1;
  right: 130%;
  transition-property: right, top, opacity;
  transition-duration: 1s, 0.5s, 1.15s;
  transition-timing-function: ease;
}

/* Active state */
.shine:active:after,
.shine-quick:active:after {
  opacity: 0;
}

/* Highcharts combined chart style hack */
#shared-chart .highcharts-yaxis-grid .highcharts-grid-line:first-of-type {
  stroke-width: 4;
}

.track-border {
  -webkit-filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black);
  filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black);
}

.clip-diagonal-top {
  -webkit-clip-path: polygon(100% 0%, 0% 100%, 0% 0%, 100% 0%);
  clip-path: polygon(100% 0%, 0% 100%, 0% 0%, 100% 0%);
}

.clip-diagonal-bottom {
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.hide-arrows {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  margin: 0;
}

.slick-dots .slick-active-cancel-margin li {
  margin: 0 0px;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.ltr {
  right: 0;
}

.resizer.rtl {
  left: 0;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

@media (min-width: 1020px) {
  .event-page-header-content-gradient {
    background: transparent;
  }
}

.gst_header {
  background-color: hsla(262, 0%, 0%, 1);
  background-image: radial-gradient(
      at 41% 43%,
      hsla(314, 100%, 40%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 34% 93%, hsla(266, 100%, 22%, 1) 0px, transparent 50%),
    radial-gradient(at 58% 49%, hsla(302, 100%, 23%, 1) 0px, transparent 50%),
    radial-gradient(at 34% 31%, hsla(240, 100%, 42%, 1) 0px, transparent 50%),
    radial-gradient(at 65% 60%, hsla(314, 100%, 40%, 1) 0px, transparent 50%);
}
